import { Card } from '@app/components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UploadControl } from '@app/components/forms'
import { CaseFormFields } from '../types'
import { RadioIdentifiers } from '@app/services/files/types'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useHandleUpload } from '@app/hooks/files/useHandleUpload'

import './RadiographsForm.scss'

export const RadiographsForm: FC = () => {
  const { caseId } = useParams()
  const { t } = useTranslation()
  const { getValues } = useFormContext<CaseFormFields>()
  const { handleUpload } = useHandleUpload(caseId!)

  const files = getValues('files')

  return (
    <Card.Body className="RadiographsForm" size={'sm'}>
      <h3 className="form-subtitle">{t('cases.multimedia.radio')}</h3>
      <div className="FilesForm-list">
        {Object.keys(files.radiographs).map(identifier => (
          <div className="FilesForm-item" key={identifier}>
            <UploadControl<CaseFormFields>
              name={`files.radiographs.${identifier as RadioIdentifiers}.file`}
              label={t(`cases.multimedia.${identifier}`)}
              onChange={handleUpload}
              hiddenLabel
              identifier={identifier as RadioIdentifiers}
            />
          </div>
        ))}
      </div>
    </Card.Body>
  )
}
