import frontalImage from '@assets/images/uploadFiles/frontalImage.svg'
import retractorsImage from '@assets/images/uploadFiles/retractorsImage.svg'
import profileImage from '@assets/images/uploadFiles/profileImage.svg'
import upperOcclusalImage from '@assets/images/uploadFiles/upperOcclusalImage.svg'
import lowerOcclusalImage from '@assets/images/uploadFiles/lowerOcclusalImage.svg'
import frontalOcclusalImage from '@assets/images/uploadFiles/frontalOcclusalImage.svg'
import leftOcclusalImage from '@assets/images/uploadFiles/leftOcclusalImage.svg'
import rightOcclusalImage from '@assets/images/uploadFiles/rightOcclusalImage.svg'
import radiographyOrthopantomographyImage from '@assets/images/uploadFiles/radiographyOrthopantomographyImage.svg'
import radiographyTeleradiographyImage from '@assets/images/uploadFiles/radiographyTeleradiographyImage.svg'
import cbctImage from '@assets/images/uploadFiles/cbctImage.svg'
import upperImage from '@assets/images/uploadFiles/upperImage.svg'
import lowerImage from '@assets/images/uploadFiles/lowerImage.svg'

import {
  PhotoIdentifiers,
  RadioIdentifiers,
  STLIdentifiers,
} from '@app/services/files/types.ts'

export const getDefaultImage = (identifier?: string): string => {
  switch (identifier) {
    case PhotoIdentifiers.PhotographsFrontal:
      return frontalImage
    case PhotoIdentifiers.PhotographsRetractors:
      return retractorsImage
    case PhotoIdentifiers.PhotographsProfile:
      return profileImage
    case PhotoIdentifiers.UpperOcclusal:
      return upperOcclusalImage
    case PhotoIdentifiers.LowerOcclusal:
      return lowerOcclusalImage
    case PhotoIdentifiers.FrontalOcclusal:
      return frontalOcclusalImage
    case PhotoIdentifiers.LeftOcclusal:
      return leftOcclusalImage
    case PhotoIdentifiers.RightOcclusal:
      return rightOcclusalImage
    case RadioIdentifiers.RadioOrth:
      return radiographyOrthopantomographyImage
    case RadioIdentifiers.RadioTel:
      return radiographyTeleradiographyImage
    case RadioIdentifiers.CBCT:
      return cbctImage
    case STLIdentifiers.Upper:
      return upperImage
    case STLIdentifiers.Lower:
      return lowerImage
    default:
      return ''
  }
}
