import { Case, MeasuresRequest } from '@app/services/cases/types'
import { enumKeys, getFileById } from '@app/utils'
import {
  MeasuresFields,
  FilesStlsFormFields,
  MeasuresFormFields,
} from '../types'
import { STLIdentifiers } from '@app/services/files/types.ts'

export function measuresCoerceFilesValues(caseData: Case): MeasuresFormFields {
  const stlsKeys = enumKeys(STLIdentifiers)

  const stls = stlsKeys.reduce((acc, key) => {
    const fileById = getFileById(caseData.files, STLIdentifiers[key])
    acc[STLIdentifiers[key] as STLIdentifiers] = {
      identifier: fileById?.identifier,
      originalName: fileById?.fileName ?? '',
      file: fileById?.url ?? '',
    }

    return acc
  }, {} as FilesStlsFormFields)

  return {
    files: {
      stls,
    },
  }
}

export function measuresCoerceValues(): MeasuresFields {
  return {
    measurement: {
      method: undefined as never,
    },
    accept: undefined as never,
    published: false,
  }
}

export function mapMeasuresForRequest(
  formValues: MeasuresFields,
): MeasuresRequest {
  return {
    measurementMethod: formValues.measurement?.method as undefined as never,
    published: formValues.published,
  }
}
