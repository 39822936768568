import {
  BreadcrumbDynamic,
  ButtonAsync,
  Card,
  PageHeader,
} from '@app/components'
import { Clinic, ClinicRequest, DaysOfWeek } from '@app/services/clinics/types'
import { Place } from '@app/services/places/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  CheckControl,
  Form,
  InputControl,
  SelectControl,
} from '@app/components/forms'
import { buildValidationSchema } from './validation'
import { coerceValues, mapClinicFormForRequest } from './utils'
import { ClinicFormFields } from './types'
import { Link } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'
import { CiFloppyDisk } from 'react-icons/ci'
import { generateIntervalHours } from '@app/utils/dates'
import { UploadControl } from '@app/components/forms/upload-control/UploadControl'
import { ItemBreadCrumb } from '@app/components/breadcrumb-dynamic/types'
import { useFile } from '@app/hooks/files/useFile'

const START_HOUR = '07:00'
const END_MORNING_HOUR = '12:00'
const END_HOUR = '23:30'

interface ClinicFormProps {
  countries: Place[]
  getProvinces: (countryCode: string) => Promise<void>
  getCities: (provinceCode: string) => Promise<void>
  onSubmit: (values: ClinicRequest) => Promise<void>
  clinic?: Clinic
  provinces?: Place[]
  cities?: Place[]
}

export const ClinicForm: FC<ClinicFormProps> = ({
  clinic = {} as Clinic,
  countries,
  provinces = [],
  cities = [],
  getProvinces,
  getCities,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const { fileToBase64 } = useFile()
  const [disabledProvince, setDisabledProvince] = useState(false)
  const [disabledCity, setDisabledCity] = useState(false)
  const [loadingProvince, setLoadingProvince] = useState(false)
  const [loadingCity, setLoadingCity] = useState(false)

  const methods = useForm<ClinicFormFields>({
    mode: 'onChange',
    defaultValues: coerceValues(clinic),
    resolver: yupResolver(buildValidationSchema(t)),
  })
  const {
    getValues,
    watch,
    setFocus,
    setValue,
    clearErrors,
    formState: { isSubmitting, isValid, isDirty },
  } = methods

  const countryWatch = watch('country')
  const provinceWatch = watch('province')

  useEffect(() => {
    setDisabledProvince(!countryWatch)
    setDisabledCity(!countryWatch || !provinceWatch)
  }, [countryWatch, provinceWatch])

  const onChangeCountry = useCallback(
    (value: string | boolean) => {
      const stringValue = String(value) // Convertir a cadena
      setLoadingProvince(true)
      setValue('province', '')
      setValue('city', '')
      clearErrors('province')
      clearErrors('city')
      getProvinces(stringValue).finally(() => {
        setLoadingProvince(false)
        setFocus('province')
      })
    },
    [clearErrors, getProvinces, setFocus, setValue],
  )

  const onChangeProvince = useCallback(
    (value: string | boolean) => {
      const stringValue = String(value) // Convertir a cadena
      setLoadingCity(true)
      setValue('city', '')
      clearErrors('city')
      getCities(stringValue).finally(() => {
        setLoadingCity(false)
        setFocus('city')
      })
    },
    [clearErrors, getCities, setFocus, setValue],
  )

  const intervalMorningHours = useMemo(
    () => generateIntervalHours(START_HOUR, END_MORNING_HOUR),
    [],
  )

  const intervalAfternoonHours = useMemo(
    () => generateIntervalHours(END_MORNING_HOUR, END_HOUR),
    [],
  )

  const handleSubmitForm: SubmitHandler<ClinicFormFields> = async (
    formValues: ClinicFormFields,
  ) => {
    const fileBase64 =
      formValues.logo && formValues.logo.length > 0
        ? await fileToBase64(formValues.logo[0])
        : ''

    const request: ClinicRequest = mapClinicFormForRequest(
      formValues,
      fileBase64,
    )
    onSubmit(request)
  }

  const breadcrumbItems: ItemBreadCrumb[] = [
    { label: t('menu.clinics'), href: '/clinics' },
    { label: clinic.name || t('clinics.new') },
  ]

  return (
    <>
      <PageHeader id="clinic-edit-page">
        <BreadcrumbDynamic
          items={breadcrumbItems}
          activeItem={clinic.name || t('clinics.new')}
        />
        {clinic.name}
      </PageHeader>
      <FormProvider {...methods}>
        <Form onValid={handleSubmitForm}>
          <Card>
            <Card.Body size={'sm'}>
              <div className="row">
                <div className="col-12 col-sm-3 col-lg-2">
                  <UploadControl<ClinicFormFields>
                    name="logo"
                    hiddenLabel
                    label={t('clinics.logo')}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <InputControl
                    type="text"
                    name="name"
                    label={t('clinics.name')}
                    placeholder={t('clinics.name')}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputControl
                    type="email"
                    name="email"
                    label={t('clinics.email')}
                    placeholder={t('clinics.email')}
                    required
                  />
                </div>
                <div className="col-12">
                  <InputControl
                    type="text"
                    name="address"
                    label={t('clinics.address')}
                    placeholder={t('clinics.address')}
                    required
                  />
                </div>
                <div className="col-12 col-md-4">
                  <InputControl
                    type="text"
                    name="addressNumber"
                    label={t('clinics.address-number')}
                    placeholder={t('clinics.address-number')}
                    required
                  />
                </div>
                <div className="col-12 col-md-4">
                  <InputControl
                    type="text"
                    name="addressExtra"
                    label={t('clinics.address-floor')}
                    placeholder={t('clinics.address-floor')}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <InputControl
                    type="text"
                    name="postalCode"
                    label={t('clinics.postal-code')}
                    placeholder={t('clinics.postal-code')}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputControl
                    type="text"
                    name="phone"
                    label={t('clinics.phone')}
                    placeholder={t('clinics.phone')}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectControl
                    name="country"
                    label={t('clinics.country')}
                    options={countries.map(d => ({
                      value: d.id,
                      label: d.name,
                    }))}
                    onChange={value => onChangeCountry(String(value))}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectControl
                    name="province"
                    label={t('clinics.province')}
                    disabled={disabledProvince}
                    loading={loadingProvince}
                    options={provinces?.map(d => ({
                      value: d.id,
                      label: d.name,
                    }))}
                    onChange={value => onChangeProvince(String(value))}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectControl
                    name="city"
                    label={t('clinics.city')}
                    disabled={disabledCity}
                    loading={loadingCity}
                    options={cities?.map(d => ({
                      value: d.id,
                      label: d.name,
                    }))}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <h3 className="form-subtitle">{t('clinics.opening-hours')}</h3>
                <div className="d-flex gap-3 flex-wrap">
                  {Object.keys(getValues('openingHours.days')).map(day => (
                    <CheckControl<ClinicFormFields>
                      key={day}
                      name={`openingHours.days.${day as keyof typeof DaysOfWeek}`}
                      label={t(`days.${day}`)}
                    />
                  ))}
                </div>
                <div className="col-12 col-md-3">
                  <SelectControl
                    name="openingHours.startTimeMorning"
                    label={t('clinics.morning-opening')}
                    options={intervalMorningHours.map(d => ({
                      value: d,
                      label: d,
                    }))}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <SelectControl
                    name="openingHours.endTimeMorning"
                    label={t('clinics.morning-close')}
                    options={intervalMorningHours.map(d => ({
                      value: d,
                      label: d,
                    }))}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <SelectControl
                    name="openingHours.startTimeEvening"
                    label={t('clinics.afternoon-opening')}
                    options={intervalAfternoonHours.map(d => ({
                      value: d,
                      label: d,
                    }))}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <SelectControl
                    name="openingHours.endTimeEvening"
                    label={t('clinics.afternoon-close')}
                    options={intervalAfternoonHours.map(d => ({
                      value: d,
                      label: d,
                    }))}
                  />
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Link className="btn btn-secondary" to="../">
                <BsChevronLeft />
                {t('common.back')}
              </Link>
              <ButtonAsync
                className="btn btn-primary"
                disabled={!isValid && !isDirty}
                isSubmitting={isSubmitting}
              >
                <CiFloppyDisk />
                {t('common.save')}
              </ButtonAsync>
            </Card.Footer>
          </Card>
        </Form>
      </FormProvider>
    </>
  )
}
