import clsx from 'clsx'
import { FC, useState } from 'react'
import Image from 'react-bootstrap/esm/Image'
import { useTranslation } from 'react-i18next'
import { CiImageOn } from 'react-icons/ci'
import { MediaMenuActions } from './media-menu-actions/MediaMenuActions'
import { getDefaultImage } from '@app/utils/images/getDefaultImage'
import { Loading } from '@app/components'

import './MediaPreview.scss'
import { STLIdentifiers } from '@app/services/files/types.ts'

interface MediaPreviewProps {
  id: string
  identifier?: string
  handleClickImage?: (id: string) => void
  handleClear?: () => void
  handleUpload?: () => void
  title?: string
  url?: string
  urlVariant?: string
}

const previewExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp']

export const MediaPreview: FC<MediaPreviewProps> = ({
  id,
  identifier,
  url,
  urlVariant,
  title,
  handleClear,
  handleUpload,
  handleClickImage,
}) => {
  const { t } = useTranslation()
  const [isUploading, setIsUploading] = useState(false)
  const label = title ?? t('common.no-file')
  const dontShowPreviewImage = [STLIdentifiers.Upper, STLIdentifiers.Lower]

  const handleEmptyImageClick = () => {
    if (handleUpload) {
      setIsUploading(true)
      handleUpload()
      setIsUploading(false)
    }
  }

  const defaultImage = getDefaultImage(identifier)

  function shouldPreviewImage() {
    const imageUrl = urlVariant || url

    if (
      identifier &&
      dontShowPreviewImage.includes(identifier as STLIdentifiers)
    ) {
      return false
    }

    if (!imageUrl) {
      return false
    }

    const extension = imageUrl.split('.').pop()?.toLowerCase()

    return (
      imageUrl.startsWith('data:image') ||
      previewExtensions.includes(extension!)
    )
  }

  const imageUrl = urlVariant || url

  const renderImage = () => {
    if (shouldPreviewImage()) {
      return (
        <Image
          src={imageUrl}
          rounded
          className={clsx('MediaPreview-image', { 'no-photo': !imageUrl })}
          onClick={
            url && handleClickImage ? () => handleClickImage(id) : undefined
          }
        />
      )
    }

    return (
      <div className="MediaPreview-image-withoutUpload">
        {defaultImage ? (
          <Image src={defaultImage} className="icon-md" />
        ) : (
          <CiImageOn className="icon-md" />
        )}
      </div>
    )
  }

  const renderEmptyImage = () => (
    <div className="MediaPreview-image" onClick={handleEmptyImageClick}>
      {defaultImage ? (
        <Image src={defaultImage} className="icon-md" />
      ) : (
        <CiImageOn className="icon-md" />
      )}
    </div>
  )

  return (
    <div className="MediaPreview">
      {isUploading && (
        <div className="MediaPreview-loading-container">
          <Loading />
        </div>
      )}
      {url ? renderImage() : renderEmptyImage()}
      <div className="MediaPreview-menu">
        <p className="text-truncate m-0">{label}</p>
        <MediaMenuActions
          id={id}
          url={url}
          handleUpload={() => {
            setIsUploading(true)
            handleUpload?.()
            setIsUploading(false)
          }}
          handleClear={() => {
            setIsUploading(true)
            handleClear?.()
            setIsUploading(false)
          }}
        />
      </div>
    </div>
  )
}
