import {
  CaseArcadeType,
  CaseCutType,
  CasePrintingType,
  CaseSecretRetainerType,
  CaseTargetType,
  CaseType,
  CasesTeethNotMove,
  GenderType,
} from '@app/services/cases/types'
import {
  CaseFileType,
  STLIdentifiers,
  PhotoIdentifiers,
  RadioIdentifiers,
} from '@app/services/files/types'

export enum CaseStep {
  Type = 0,
  General = 1,
  Specific = 2,
  Files = 3,
  Radiographs = 4,
  Measures = 5,
}

export type PatientProblemForm = {
  problemId: string
  value: boolean
  solutionId?: string
  otherSolution?: string
}

export type StepForm = {
  currentStep?: CaseStep
}

export type FileFormFields = Partial<{
  identifier: CaseFileType
  originalName: string
  file: string
}>

export type FilesPhotoFormFields = {
  [PhotoIdentifiers.PhotographsFrontal]: FileFormFields
  [PhotoIdentifiers.PhotographsRetractors]: FileFormFields
  [PhotoIdentifiers.PhotographsProfile]: FileFormFields
  [PhotoIdentifiers.UpperOcclusal]: FileFormFields
  [PhotoIdentifiers.LowerOcclusal]: FileFormFields
  [PhotoIdentifiers.FrontalOcclusal]: FileFormFields
  [PhotoIdentifiers.LeftOcclusal]: FileFormFields
  [PhotoIdentifiers.RightOcclusal]: FileFormFields
  // [PhotoIdentifiers.PhotographsHighlight]: FileFormFields
}

export type FilesRadioFormFields = {
  [RadioIdentifiers.RadioOrth]: FileFormFields
  [RadioIdentifiers.RadioTel]: FileFormFields
  [RadioIdentifiers.CBCT]: FileFormFields
}

export type FilesStlsFormFields = {
  [STLIdentifiers.Upper]: FileFormFields
  [STLIdentifiers.Lower]: FileFormFields
}

export type FilesFormFields = {
  files: {
    photographs: FilesPhotoFormFields
  }
}

export type RadioFormFields = {
  files: {
    radiographs: FilesRadioFormFields
  }
}

export type MeasuresFormFields = {
  files: {
    stls: FilesStlsFormFields
  }
}

export type MeasuresFields = {
  measurement: {
    method?: CasePrintingType
  }
  accept: boolean
  published: boolean
}

export type SpecificInfoFields = {
  specific: {
    cutType: CaseCutType
    secretRetainers: CaseSecretRetainerType
    useElastics?: boolean
    distalize?: boolean
    thirdMolarExtraction?: boolean
    target: CaseTargetType
    patientProblems: PatientProblemForm[]
    indications: string
  }
}

export type CaseCreateFormFields = StepForm & {
  type: CaseType | undefined
  general: {
    firstName: string
    lastName: string
    gender: GenderType | undefined
    birthdate: string
    archTreat: CaseArcadeType | undefined
    teethNotToMove: CasesTeethNotMove[]
    comments: string
    clinicId: string
    billingAddressId: string
  }
}

export type CaseEditFormFields = StepForm &
  CaseCreateFormFields &
  SpecificInfoFields &
  FilesFormFields &
  RadioFormFields &
  MeasuresFields &
  MeasuresFormFields

export type CaseFormFields = CaseEditFormFields
