import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { CaseFile, CaseFileRequest } from './types'
import { ResponseData } from '@app/core/types'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'

const getBaseUrl = (config: IConfigService, caseId: string) =>
  `${config.baseUrl}/product-cases/${caseId}/files`

const getCaseFiles =
  (config: IConfigService, http: IHttpClient) =>
  async (caseId: string): Promise<CaseFile[]> => {
    try {
      const { data } = await http.get<ResponseData<CaseFile[]>>(
        `${getBaseUrl(config, caseId)}`,
      )

      return data
    } catch (e) {
      throw errorFactory(e)
    }
  }

const uploadCaseFile =
  (config: IConfigService, http: IHttpClient) =>
  async (caseId: string, request: CaseFileRequest): Promise<CaseFile> => {
    const formData = new FormData()
    formData.append('file', request.file)
    formData.append('identifier', request.identifier.toString())
    formData.append('originalName', request.originalName)

    const { data } = await http.post<FormData, ResponseData<CaseFile>>(
      `${getBaseUrl(config, caseId)}/${request.identifier.toString()}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )

    return data
  }

const deleteCaseFile =
  (config: IConfigService, http: IHttpClient) =>
  async (caseId: string, fileId: string): Promise<void> => {
    return await http.delete(`${getBaseUrl(config, caseId)}/${fileId}`)
  }

const fileToBase64 =
  () =>
  async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = readerEvent => {
        const base64String = readerEvent?.target?.result as string
        resolve(base64String)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  getCaseFiles: getCaseFiles(config, http),
  uploadCaseFile: uploadCaseFile(config, http),
  deleteCaseFile: deleteCaseFile(config, http),
  fileToBase64: fileToBase64(),
})

export default factory(config, http)
